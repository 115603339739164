 .page_404 {
   width: 70%;
   height: 80%;
   padding: 40px 0;
   background: #fff;
   font-family: "Arvo", serif;
   border-radius: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
     sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }

 .page_404 img {
   width: 100%;
 }

 .four_zero_four_bg {
   background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
   height: 400px;
   width: 700px;
   background-position: center;
 }

 @media (max-width:1350px) {
   .four_zero_four_bg {
     height: 300px;
     width: 100%;
   }
 }
 @media (max-width:480px) {
   .page_404 {
     height: 400px;
     width: 100%;
   }
 }

 .four_zero_four_bg h1 {
   font-size: 80px;
   text-align: center;

 }

 .four_zero_four_bg h3 {
   font-size: 80px;
   text-align: center;
 }

 .link_404 {
   color: #fff !important;
   padding: 10px 20px;
   background: #39ac31;
   margin: 20px 0;
   display: inline-block;
 }

 .contant_box_404 {
   width: 100%;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: -50px;
 }

 .link_404 {
   border-radius: 10px;
   text-decoration: none;
 }

 .error-container {
   margin-top: 60px;
   width: 100%;
   height: 81.8vh;
   display: flex;
   align-items: center;
   justify-content: center;
 }