.input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: .9vw;
  color: #fff;
  background-color: #030d12;
  box-shadow: 0 0 .4vw rgba(3, 10, 26, 0.5), 0 0 0 .15vw transparent;
  border-radius: 0.4vw;
  border: 1px solid rgb(8, 28, 41);
  outline: none;
  padding: 0.7vw;
  width: 100%;
  transition: .4s;
}

.textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1vw;
  color: #100f0f;
  background-color: #ffffff;
  border-radius: 0.4vw;
  border: 1px solid rgb(170, 170, 170);
  outline: none;
  padding: 0.7vw;
  width: 100%;
  height: 250px;
  transition: .4s;
}

.input:hover {
  box-shadow: 0 0 0 .15vw rgba(135, 207, 235, 0.186);
}

.textarea:hover {
  box-shadow: 0 0 0 .15vw rgba(135, 207, 235, 0.186);
}

.textarea:focus {
  box-shadow: 0 0 0 .15vw rgba(135, 207, 235, 0.784);
}

.input:focus {
  box-shadow: 0 0 0 .15vw rgba(135, 207, 235, 0.784);
}